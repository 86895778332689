.image_css {
  cursor: pointer;
  max-width: 100%;
  height: auto;
}

.order_now {
  text-align: center;
  margin-top: -20px;
}

.decide_image img {
  width: 100%;
}

#decideModal .modal-body {
  padding: 8px !important;
}

#decideModal .close img {
  width: 40px;
  height: 40px;
  margin: 3px 3px 0px 0px;
}

.orderNowBtn {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #000000;
  color: #fff;
  border: 1px solid #000000;
  border-radius: 7px;
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 18px; /* Adjust the gap between text and image if needed */
}

.buttonContent img {
  width: 30px;
  height: 30px;
}

@media (min-width: 992px) {
  .what_cont .row {
    position: relative;
  }

  .what_cont .col-sm-4:last-child {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .image_css {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .buttonContent img {
    width: 20px;
    height: 20px;
  }

  .buttonContent {
    font-size: 16px;
  }

  .orderNowBtn {
    padding: 8px 15px;
  }
}
