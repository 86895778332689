.modalPop {
  position: fixed;
  background-color: rgb(0 0 0 / 60%);
  z-index: 1050;
  width: 100%;
  height: 100vh;
  transition: 0.5s all ease-in-out;
  top: 0px;
  left: 0px;
  overflow: auto;
}
.furIyG {
  z-index: 9;
}
.blockstate {
  opacity: 0.5;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.details_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: rgb(237, 177, 11);
  color: rgba(255, 255, 255, 1);
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.details_disable_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: rgb(243, 214, 135);
  color: rgba(255, 255, 255, 1);
  border-radius: 0.25rem;
  border: none;
  cursor: none;
  transition: background-color 0.3s ease-in-out;
}

.mobile_header ul {
  display: flex;
  align-items: center;
}
.mobile_header ul li {
  float: left;
  width: 33.33%;
  list-style-type: none;
  padding: 12px 0px;
}
.mobile_header ul li:nth-child(2) {
  border-left: 1px solid rgb(196 196 196);
  border-right: 1px solid rgb(196 196 196);
}
.mobile_header ul li a {
  font-family: "FuturaNormal";
  font-size: 12px;
  font-weight: 400;
  color: rgba(76, 76, 76, 1) !important;
  text-align: center;
  float: left;
  width: 100%;
}
.mobile_header ul li a img {
  margin-bottom: 6px;
}
.mobile_videocall {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  border: none;
  padding: 15px 0px;
  background-color: rgb(0, 0, 0);
  font-family: "FuturaNormal";
}
.mobile_links li {
  list-style-type: none;
}

.mobile_links li a {
  font-family: "FuturaNormal";
  font-size: 12px;
  font-weight: 400;
  color: rgba(76, 76, 76, 1) !important;
  text-align: center;
  float: left;
  width: 100%;
  position: relative;
}
.mobile_links li a span {
  position: absolute;
  top: -2px;
  right: -5px;
  background-color: #f16565;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 14px;
  height: 14px;
  line-height: 14px;
  font-size: 8px;
  border-radius: 50%;
}
.mobile_links li a img {
  margin-bottom: 6px;
}
.mobile_links {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
}
.mobile_links li:first-child {
  margin-right: 15px;
}
/* .video_pop input, .video_pop select{
  text-align: left;
   
    padding-left: 0;
}
.DatePicker{
  width:100%
} */

@media (max-width: 767px) {
  #videoModal {
    padding-bottom: 60px;
  }
}
