@font-face {
  font-family: "FuturaBold";
  src: url("../fonts/futura/FuturaPTBold.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "FuturaNormal";
  src: url("../fonts/futura/FuturaPTBook.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "FuturaLight";
  src: url("../fonts/futura/FuturaPTLight.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "FuturaMedium";
  src: url("../fonts/futura/FuturaPTMedium.otf") format("opentype");
  font-display: swap;
}

iframe {
  pointer-events: none;
}

.flt {
  float: left;
  width: 100%;
}

ul {
  padding: 0px;
  margin: 0px;
}

.alert-danger,
.alert-success {
  z-index: 9999;
}

.header_container {
  display: flex;
  align-items: center;
  padding: 15px 35px;
}

.header_links ul li {
  display: inline-block;
  text-align: center;
  padding: 0px 15px;
  position: relative;
}

.header_links ul li a {
  font-family: "FuturaNormal";
  font-size: 12px;
  font-weight: 400;
  color: rgba(118, 118, 118, 1) !important;
  text-align: center;
  line-height: 24px;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  position: relative;
}

.header_links ul li a span {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: #f16565;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  border-radius: 50%;
}

.header_links ul li a:hover {
  color: rgb(0, 0, 0) !important;
  transition: 0.2s all ease-in-out;
}

.header_logo {
  text-align: center;
  width: 30%;
}

.header_links {
  width: 35%;
}

.header_links button {
  font-family: "FuturaMedium";
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
  border: none;
  background-image: linear-gradient(
    90deg,
    rgba(255, 243, 166, 1) 0%,
    rgba(249, 223, 123, 1) 0%,
    rgba(221, 183, 78, 1) 0%,
    rgba(181, 126, 16, 1) 0%,
    rgba(238, 194, 93, 1) 0%,
    rgba(235, 188, 92, 1) 41%,
    rgba(225, 170, 58, 1) 95%
  );
  border-radius: 5px;
  padding: 10px;
  margin-left: 40px;
  margin-right: 15px;
}

.header_links_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown_menu {
  position: absolute;
  width: 173px;
  top: 60px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px rgb(98 98 98 / 35%);
  z-index: 99;
}

.dropdown_menu li {
  display: block;
  float: left;
  width: 100%;
  padding: 0px !important;
  border-bottom: 1px solid rgb(241 241 241);
}

.dropdown_menu li:last-child {
  border-bottom: none;
}

.dropdown_menu li a {
  float: left;
  width: 100%;
  text-align: left !important;
  padding: 10px 20px !important;
}

.dropdown_menu li a img {
  margin-right: 10px;
}

.menuH_container {
  border-top: 1px solid rgb(241 241 241);
  border-bottom: 1px solid rgb(241 241 241);
  z-index: 9;
  position: relative;
}

.other_option {
  text-align: center;
}

.other_option li {
  list-style-type: none;
  float: left;
  width: 33.33%;
}

.other_option li a {
  font-family: "FuturaNormal";
  font-size: 12px;
  font-weight: 500;
  color: rgb(79 79 79) !important;
  letter-spacing: 0px;
  text-decoration: none;
  float: left;
  width: 100%;
  background: rgb(243 243 243);
  border: 1px solid rgb(255 255 255);
  padding: 15px 0px;
  min-height: 75px;
  cursor: pointer;
  position: relative;
}

.other_option li a img {
  margin-bottom: 2px;
}

.other_option li a b {
  position: relative;
}

.other_option li a b span {
  position: absolute;
  top: -4px;
  right: -8px;
  background-color: #f16565;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 14px;
  height: 14px;
  line-height: 14px;
  font-size: 8px;
  border-radius: 50%;
}

.other_option li:last-child {
  width: 100%;
}

.other_option button {
  font-family: "FuturaNormal";
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgb(255 255 255);
  padding: 10px;
  width: 100%;
  min-height: 50px;
  line-height: 17px;
  background-color: rgb(0, 0, 0);
}

.banner_container img {
  width: 100%;
}

.category_container {
  padding: 46px 37px 0px 37px;
}

.arrow_head {
  text-align: center;
  margin-bottom: 40px;
}

.arrow_head span {
  font-family: "GFS Didot", serif;
  font-size: 25px;
  text-align: center;
  letter-spacing: 1px;
  line-height: 25px;
  text-transform: uppercase;
  position: relative;
  color: rgb(0, 0, 0);
  padding-bottom: 10px;
}

.arrow_head span::before {
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  background-color: rgb(0, 0, 0);
  height: 2px;
  width: 50%;
}

.catregory_cont {
  background-image: url(../images/category-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.catregory_cont .slick-track {
  display: flex;
}

.category_box {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  box-shadow: 3px 6px 4px 0 rgb(0 0 0 / 11%);
  padding: 8px;
  margin: 20px 0px;
  cursor: pointer;
}

.catregory_cont .slick-slide > div {
  padding: 0 13px;
}

.category_box a {
  float: left;
  width: 100%;
}

.category_image img {
  width: 100%;
  border-radius: 5px;
}

.category_name {
  font-family: "FuturaNormal";
  font-size: 22px;
  font-weight: 500;
  color: rgba(24, 24, 26, 1);
  letter-spacing: 0px;
  line-height: 25px;
  text-align: center;
  padding: 14px 0px 6px 0px;
}

.what_container {
  padding: 54px 50px;
}

.what_image {
  overflow: hidden;
}

.what_image:hover img {
  transition: 0.5s all ease-in-out;
  transform: scale(1.2);
}

.what_image img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.what_name {
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 1px;
  line-height: 40px;
  text-transform: uppercase;
}

.what_button {
  text-align: center;
  margin-top: 5px;
}

.what_button button {
  font-family: "FuturaNormal";
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 1px;
  line-height: 36px;
  background-color: transparent;
  padding: 2px 40px;
  border: 1px solid rgb(0, 0, 0);
  outline: none;
  transition: 0.2s all ease-in-out;
}

.what_button button:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  transition: 0.2s all ease-in-out;
}

.what_box {
  position: relative;
}

.what_txt {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  bottom: 20px;
}

.what_space {
  margin-bottom: 43px;
}

.award_container {
  background-image: url(../images/award-winning-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px 50px 80px 50px;
}

.award_container .row {
  align-items: center;
}

.award_left {
  position: relative;
}

.award_left::before {
  content: "";
  background-image: url(../images/square.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  position: absolute;
  right: -40px;
  bottom: -40px;
  width: 440px;
  height: 320px;
  z-index: 0;
}

.award_left iframe {
  z-index: 1;
  position: relative;
  border-radius: 10px;
  pointer-events: auto !important;
}

.award_right {
  font-family: "GFS Didot", serif;
  font-size: 40px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  padding-left: 12%;
}

.customer_container {
  background-image: url(../images/customer-connect-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px 50px 80px 50px;
}

.customer_container .row {
  align-items: center;
}

.customer_left {
  text-align: right;
  padding-right: 12%;
}

.customer_left span {
  font-family: "GFS Didot", serif;
  font-size: 40px;
  font-weight: 400;
  color: rgb(163 75 63);
}

.customer_right {
  position: relative;
}

.customer_right iframe {
  z-index: 1;
  position: relative;
  border-radius: 10px;
  pointer-events: auto !important;
}

.customer_right::before {
  content: "";
  background-image: url(../images/square.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  position: absolute;
  left: -40px;
  bottom: -40px;
  width: 440px;
  height: 320px;
  z-index: 0;
}

.feature_container {
  padding: 60px 50px 0px 50px;
}

.feature_box {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 250, 232, 1);
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgb(61 52 166 / 9%);
  min-height: 145px;
  padding: 0px 20px;
}

.buyback_box {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 250, 232, 1);
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgb(61 52 166 / 9%);
  padding: 20px 20px;
  text-align: center;
}

.feature_icon {
  margin-right: 12px;
}

.feature_txt {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 24px;
}

.feature_txt span {
  font-family: "FuturaMedium";
  font-size: 16px;
}

.buybox_txt {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 35px;
}

.divider_main {
  padding: 50px 50px 0px 50px;
}

.divider {
  border-bottom: 1px solid rgba(211, 211, 211, 0.2);
}

.footer_container {
  padding: 50px 50px 50px 50px;
  display: flex;
  background-image: url("../images/footer-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.jewel_header {
  background-image: url("../images/footer-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.footer_box {
  width: 20%;
}

.footer_head {
  font-family: "FuturaMedium";
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 25px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.footer_cont ul li {
  list-style-type: none;
  margin-bottom: 20px;
}

.footer_cont ul li a {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
  text-decoration: none;
}

.footer_media ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}

.footer_media ul li:last-child {
  margin-right: 0px;
}

.copyright_container {
  background-color: rgba(240, 240, 240, 1);
  padding: 13px 50px;
}

.copyright_container .row {
  align-items: center;
}

.copyright_left ul li {
  list-style-type: none;
  display: inline-block;
  font-family: "FuturaNormal";
  font-size: 11px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 20px;
  padding: 0px 8px;
  position: relative;
}

.copyright_left ul li:first-child {
  padding-left: 0px;
}

.copyright_left ul li:last-child {
  padding-right: 0px;
}

.copyright_left ul li a {
  color: rgba(0, 0, 0, 1);
}

.copyright_left ul li::before {
  content: "";
  position: absolute;
  height: 18px;
  width: 1px;
  background-color: rgba(124, 135, 142, 0.22);
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.copyright_left ul li:first-child::before {
  display: none;
}

.copyright_right {
  text-align: right;
}
.copyright_right img {
  max-width: 100%;
}

.video_pop .modal-title {
  font-family: "FuturaNormal";
  font-size: 12px;
  font-weight: 400;
  color: rgba(100, 100, 100, 1);
  line-height: 24px;
  margin-bottom: 28px;
}

.video_pop .modal-title span {
  color: rgba(0, 0, 0, 1);
  font-size: 22px;
}

.video_pop .form-group {
  margin-bottom: 48px;
}

.video_pop input,
.video_pop select {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 17px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgb(187, 187, 187);
  padding-bottom: 10px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  outline: none;
}

.video_pop input::placeholder,
.video_pop select::placeholder {
  color: rgb(0, 0, 0);
}

.video_pop input::placeholder {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
}

.video_pop .modal-dialog .modal-content {
  padding: 35px 30px;
}

.video_pop .close {
  position: absolute;
  right: 12px;
  top: 12px;
}

.video_pop .modal-body {
  padding: 0px;
}

.video_pop_btn {
  text-align: center;
}

.video_pop_btn button {
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 17px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  border: none;
  width: 70%;
  padding: 20px 0px;
}

.product_container {
  padding: 40px 50px;
}

.product_bar {
  background-color: rgba(247, 247, 247, 1);
  padding: 10px;
}

.product_bar input {
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 4px;
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 17px;
  float: left;
  width: 100%;
  padding: 10px;
  outline: none;
  padding-left: 40px;
}

.product_bar input::placeholder {
  color: rgba(165, 163, 163, 1);
}

.product_bar span {
  float: left;
  width: 100%;
  position: relative;
}

.product_bar span::before {
  content: "";
  background-image: url(../images/header-icon6.svg);
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.product_box {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  padding: 10px;
  margin-top: 30px;
  box-shadow: 0px 4px 8px rgb(195 195 195 / 70%);
}

.product_image {
  margin-bottom: 20px;
  cursor: pointer;
}

.product_desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product_name {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
  text-transform: uppercase;
  width: 50%;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.product_rate {
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
  width: 50%;
  text-transform: uppercase;
  text-align: right;
}

.product_rate span {
  font-family: "FuturaNormal";
}

.product_weight {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
  text-transform: uppercase;
  margin-top: 10px;
}

.product_image img {
  width: 100%;
}

.heart {
  width: 30px;
  height: 30px;
  text-align: center;
  color: rgb(203 203 203);
  background-color: rgb(247 247 247);
  line-height: 34px;
  border-radius: 50%;
  position: absolute;
  font-size: 18px;
  box-shadow: 0px 0px 5px rgb(215 215 215 / 49%);
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.heart_red {
  color: rgb(243 33 0) !important;
}

.breadcrumbs {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(115, 115, 115, 1);
  line-height: 17px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.breadcrumbs a {
  color: rgba(115, 115, 115, 1);
  text-decoration: none !important;
  cursor: pointer;
}

.breadcrumbs span {
  color: rgba(0, 0, 0, 1);
}

.filter_box {
  border-bottom: 1px solid rgba(231, 231, 231, 1);
}

.filter_head {
  padding: 18px 0px;
}

.filter_head a {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 23px;
  text-transform: uppercase;
  text-decoration: none;
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.filter_head a.collapsed i {
  transform: rotate(-180deg);
  transition: 0.5s all ease-in-out;
}

.filter_head a i {
  transform: rotate(0deg);
  transition: 0.5s all ease-in-out;
}

.filter_cont .form-group {
  display: block;
  margin-bottom: 18px;
}

.filter_cont .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.filter_cont .form-group label {
  position: relative;
  cursor: pointer;
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  line-height: 26px;
}

.filter_cont .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 4px;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
}

.filter_cont .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  background-image: url(../images/check.png);
  left: 2px;
  top: 6px;
  background-size: contain;
  background-repeat: no-repeat;
}

.filter_input {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.filter_input span {
  width: 40%;
  margin-right: 12px;
  position: relative;
}

.filter_input input {
  float: left;
  width: 100%;
  border: 1px solid rgba(110, 110, 110, 1);
  border-radius: 5px;
  font-family: "FuturaNormal";
  color: rgb(0, 0, 0);
  padding: 8px;
  font-size: 14px;
  outline: none;
}

.filter_input input::placeholder {
  color: rgba(182, 182, 182, 1);
}

.filter_input span::before {
  /* content: '\e1bc'; */
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}

.filter_input button {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgb(255 255 255);
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  border: none;
  width: 20%;
  padding: 8px;
}

.detail_container {
  padding: 30px 50px 40px 50px;
}

.detail_left {
  text-align: center;
  position: relative;
}

.zoom_area {
  text-align: center;
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.detail_more {
  position: absolute;
  width: 17%;
  left: 15px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detail_more li {
  float: left;
  width: 100%;
  list-style-type: none;
  display: flex;
  border: 1px solid rgb(224 224 224);
  cursor: pointer;
}

.detail_more li img {
  width: 100%;
}

.detail_name {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  color: rgb(0, 0, 0);
  line-height: 35px;
}

.detail_code {
  font-family: "GFS Didot", serif;
  font-size: 14px;
  color: rgba(180, 144, 54, 1);
  line-height: 17px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.out_of_stock {
  margin-left: 15px;
  font-weight: lighter;
  font-size: 14px;
  color: red;
}

.detail_rate {
  font-size: 14px;
  line-height: 30px;
  font-family: "GFS Didot", serif;
  color: rgba(0, 0, 0);
}

.detail_rate strike {
  color: rgba(76, 76, 76, 1);
  font-size: 16px;
}

.detail_rate span {
  font-size: 32px;
  margin-right: 10px;
}

.detail_rate b {
  font-size: 28px;
  font-weight: 500 !important;
  margin-right: 5px;
}

.detail_save {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(180, 144, 54, 1);
}

.detail_save b {
  font-family: "GFS Didot", serif;
  font-weight: normal !important;
  font-size: 13px;
}

.detail_btn {
  display: flex;
  align-items: center;
  margin: 25px 0px;
}

.detail_btn button {
  font-family: "FuturaMedium";
  font-size: 15px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 17px;
  padding: 12px;
  width: 140px;
  margin-right: 15px;
  border-radius: 5px;
}

.detail_btn button:first-child {
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
}

.detail_btn button:last-child {
  border: 1px solid rgba(6, 5, 72, 1);
  color: rgba(6, 5, 72, 1);
  background-color: rgb(255, 255, 255);
  margin-right: 0px;
}

.detail_note li {
  list-style-type: none;
  float: left;
  width: 50%;
  margin-bottom: 20px;
}

.detail_note li:last-child {
  margin-right: 0px;
}

.detail_note li a {
  font-family: "FuturaNormal";
  font-size: 13px;
  font-weight: 400;
  color: rgba(76, 76, 76, 1) !important;
  line-height: 14px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.detail_note li a img {
  margin-right: 8px;
}

.detail_enq {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  color: rgba(76, 76, 76, 1);
  line-height: 24px;
  text-transform: uppercase;
}

.detail_identity {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 15px;
}

.detail_identity li {
  list-style-type: none;
  text-align: center;
  font-size: 13px;
  color: rgba(125, 125, 125, 1);
  font-family: "FuturaNormal";
}

.detail_identity li img {
  margin-bottom: 3px;
}

.detail_pin_box {
  background-color: rgba(245, 245, 245, 1);
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 1px 1px 1px rgb(0 0 0 / 16%);
  border-radius: 3px;
}

.detail_pin_label {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
  margin-bottom: 15px;
}

.detail_pin_cont input {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgba(1, 0, 69, 1);
  line-height: 17px;
  outline: none;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid rgba(1, 0, 69, 1);
  border-radius: 5px;
  height: 40px;
}

.detail_pin_cont input::placeholder {
  color: rgba(181, 181, 181, 1);
}

.detail_pin_cont button {
  font-family: "FuturaNormal";
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 8px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  padding: 10px;
  border: 1px solid rgb(0, 0, 0);
  height: 40px;
  width: 26%;
  margin-left: 3%;
}

.speification_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.specification_main {
  margin-top: 90px;
}

.specification_box {
  border-bottom: 1px solid rgb(225 225 225);
  margin-bottom: 15px;
}

.specification_head {
  margin-bottom: 15px;
}

.specification_head a {
  font-family: "FuturaMedium";
  font-size: 18px;
  font-weight: 500;
  color: rgba(112, 81, 2, 1) !important;
  line-height: 25px;
  text-decoration: none;
  position: relative;
  float: left;
  width: 100%;
  cursor: pointer;
}

.specification_cont li {
  list-style-type: none;
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(231 231 231 / 60%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.specification_cont li:last-child {
  border: none;
}

.specification_cont li b {
  font-family: "FuturaMedium";
  font-weight: 500;
}

.specification_head a::before {
  position: absolute;
  content: "\f067";
  font-family: "Font Awesome 5 free";
  font-weight: 600;
  right: 0px;
  top: 0px;
  font-size: 16px;
  transition: 0.5s all ease-in-out;
}

.specification_head a[aria-expanded="true"]::before {
  content: "\f068";
  transition: 0.5s all ease-in-out;
}

.related_main {
  margin-top: 50px;
}

.related_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  text-align: center;
  margin-bottom: 60px;
}

.related_box {
  background-color: rgba(247, 247, 247, 1);
  padding: 18px;
  position: relative;
}

.related_image {
  margin-bottom: 18px;
}

.related_desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.related_name {
  font-family: "FuturaNormal";
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-transform: uppercase;
  width: 50%;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.related_rate {
  font-size: 17px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  width: 50%;
  text-transform: uppercase;
  text-align: right;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.related_rate span {
  font-family: "FuturaNormal";
}

.related_weight {
  font-family: "FuturaNormal";
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-transform: uppercase;
  margin-top: 8px;
}

.related_image img {
  width: 100%;
}

.related_cont .row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  margin: 0px;
}

.related_cont .col-sm-3 {
  display: inline-block;
  max-width: 22%;
  padding: 0px 25px;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: transparent;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.detail_left .heart {
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 25px;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.profile_container {
  padding: 50px 120px;
}

.profile_left li {
  list-style-type: none;
  float: left;
  width: 100%;
  margin-bottom: 2px;
}

.profile_left li a {
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 17px;
  text-decoration: none;
  padding: 20px;
  float: left;
  width: 100%;
  background-color: rgba(247, 247, 247, 1);
}

.profile_left li a img {
  margin-right: 20px;
}

.profile_right_head {
  font-family: "GFS Didot", serif;
  font-size: 25px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 17px;
  margin-bottom: 32px;
}

.profile_right_cont .form-group {
  margin-bottom: 38px;
}

.profile_right_cont .form-group label {
  float: left;
  width: 100%;
}

.profile_right_cont .form-group label {
  float: left;
  width: 100%;
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
}

.profile_right_cont .form-group input,
.profile_right_cont .form-group select {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 23px;
  border: 1px solid rgba(118, 118, 118, 1);
  border-radius: 5px;
  outline: none;
  float: left;
  width: 100%;
  padding: 16px;
  background-color: transparent;
}

.profile_right_cont .form-group input::placeholder {
  color: rgba(118, 118, 118, 1);
}

.profile_right_btn {
  border-top: 1px dashed rgb(220 219 219);
  padding-top: 38px;
}

.profile_right_btn button {
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 17px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  border: none;
  padding: 25px;
  width: 100%;
  outline: none;
}

.profile_left {
  padding-right: 15%;
}

.profile_left li a.active {
  background-color: rgba(0, 0, 0, 1);
  color: rgb(255, 255, 255);
}

.password_check {
  margin-bottom: 50px;
}

.password_check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.password_check label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}

.password_check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 4px;
}

.password_check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid rgba(0, 0, 0, 1);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.shipping_address {
  margin-top: 20px;
  margin-bottom: 50px;
}

.shipping_address input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.shipping_address label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}

.shipping_address label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 4px;
}

.shipping_address input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid rgba(0, 0, 0, 1);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.empty_order_head {
  font-family: "FuturaNormal";
  font-size: 30px;
  font-weight: 500;
  color: rgba(151, 153, 154, 1);
  text-align: center;
  margin-top: 20px;
}

.empty_order_sub_head {
  padding: 0px 150px;
  font-size: 20px;
}

.empty_order_image {
  text-align: center;
}

.order_main .nav {
  border-bottom: 1px solid rgb(187 187 187);
  margin-bottom: 25px;
  white-space: nowrap;
  overflow: auto;
}

.order_main .nav li a {
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1) !important;
  line-height: 30px;
  background-color: transparent !important;
  padding: 10px 15px;
  cursor: pointer;
}

.order_main .nav li a.active {
  font-family: "FuturaMedium";
  border-bottom: 2px solid rgba(0, 0, 0, 1);
  border-radius: 0px;
}

.order_box {
  margin-bottom: 60px;
}

.order_box_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 15px;
  background: rgba(240, 242, 242, 1);
  border: 1px solid rgba(187, 187, 187, 1);
  border-radius: 5px 5px 0 0;
}

.order_box_head li {
  list-style-type: none;
  color: rgba(111, 111, 111, 1);
  position: relative;
  font-size: 14px;
}

.order_box_head li span {
  color: rgb(0, 0, 0);
}

.order_cont {
  border-right: 1px solid rgba(187, 187, 187, 1);
  border-bottom: 1px solid rgba(187, 187, 187, 1);
  border-left: 1px solid rgba(187, 187, 187, 1);
  border-radius: 0px 0px 5px 5px;
}

.order_image img {
  width: 100%;
}

.order_desc li {
  font-family: "FuturaNormal";
  line-height: 17px;
  list-style-type: none;
  margin-bottom: 14px;
}

.order_desc .order_name {
  font-size: 25px;
  font-weight: 500;
  color: rgba(76, 76, 76, 1);
}

.order_desc .order_code {
  font-size: 16px;
  font-weight: 500;
  color: rgba(180, 144, 54, 1);
}

.order_desc .order_id {
  font-size: 13px;
  font-weight: 600;
  color: rgba(84, 84, 84, 1);
}

.order_desc .order_quantity {
  font-size: 16px;
  font-weight: 500;
  color: rgba(120, 120, 120, 1);
}

.order_desc .order_rate {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
}

.order_desc li:last-child {
  margin-bottom: 0px;
}

.order_descss li {
  font-family: "FuturaNormal";
  line-height: 17px;
  list-style-type: none;
  margin-bottom: 14px;
}

.order_descss .order_name {
  font-size: 25px;
  font-weight: 500;
  color: rgba(76, 76, 76, 1);
}

.order_descss .order_code {
  font-size: 16px;
  font-weight: 500;
  color: rgba(180, 144, 54, 1);
}

.order_descss .order_id {
  font-size: 13px;
  font-weight: 600;
  color: rgba(84, 84, 84, 1);
}

.order_descss .order_quantity {
  font-size: 16px;
  font-weight: 500;
  color: rgba(120, 120, 120, 1);
}

.order_descss .order_rate {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
}

.order_descss li:last-child {
  margin-bottom: 0px;
}

.order_desc_trial li {
  font-family: "FuturaNormal";
  line-height: 17px;
  list-style-type: none;
  margin-bottom: 14px;
}
.order_desc_trial .order_name {
  font-size: 25px;
  font-weight: 500;
  color: rgba(76, 76, 76, 1);
}

.order_desc_trial .order_code {
  font-size: 16px;
  font-weight: 500;
  color: rgba(180, 144, 54, 1);
}

.order_desc_trial .order_id {
  font-size: 13px;
  font-weight: 600;
  color: rgba(84, 84, 84, 1);
}

.order_desc_trial .order_quantity {
  font-size: 16px;
  font-weight: 500;
  color: rgba(120, 120, 120, 1);
}

.order_desc_trial .order_rate {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
}

.order_desc_trial li:last-child {
  margin-bottom: 0px;
}

.order_btn button {
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  padding: 8px;
  width: 230px;
}

.order_btn button img {
  margin-right: 15px;
}

.order_btn .download_invoice {
  border: 1px solid rgba(48, 42, 41, 1);
  color: rgba(52, 48, 48, 1);
  margin-bottom: 20px;
  background-color: transparent;
}

.order_btn .report_problem {
  color: rgba(96, 72, 72, 1);
  background-color: rgba(187, 187, 187, 1);
  border: 1px solid rgba(187, 187, 187, 1);
}

.track_order {
  border: 1px solid rgba(61, 154, 226, 1);
  background-color: transparent;
  color: rgba(61, 154, 226, 1);
}

.order_flex {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgb(212 212 212);
}

.order_flex:last-child {
  border-bottom: none;
}

.order_image {
  width: 35%;
}

.order_desc {
  width: 50%;
  padding: 0% 3%;
}

.order_desc_trial {
  width: 25%;
  padding: 10% 1% 0% 1%;
}

.total_pop {
  position: absolute;
  background-color: rgba(240, 242, 242, 1);
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
  padding-top: 10px;
  width: 320px;
  top: 40px;
  left: -15px;
  z-index: 9;
}

.order_desc_cancel {
  width: 65%;
}

.total_pop li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 15px 25px;
}

.total_pop li span {
  color: rgba(84, 84, 84, 1);
}

.total_pop li:last-child {
  margin-bottom: 0px;
  font-family: "FuturaMedium";
  font-size: 17px !important;
  color: rgba(0, 0, 0, 1) !important;
  border-top: 1px dashed rgb(212 212 212);
}

.total_pop li:last-child span {
  font-family: "FuturaMedium";
  font-size: 17px !important;
  color: rgba(0, 0, 0, 1) !important;
}

.total_pop li b {
  font-family: "GFS Didot", serif;
  font-weight: 300;
}

.order_box_head li span i {
  font-size: 13px;
  margin-left: 2px;
}

.total_pop_btn {
  cursor: pointer;
}

.order_box:last-child {
  margin-bottom: 0px;
}

.wish_box {
  background-color: rgba(247, 247, 247, 1);
  position: relative;
  margin-bottom: 30px;
}

.wish_image i {
  position: absolute;
  right: 20px;
  top: 20px;
  color: rgba(138, 138, 138, 1);
  font-size: 12px;
  border: 2px solid rgba(138, 138, 138, 1);
  line-height: 10px;
  padding: 4px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.wish_image button {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  padding: 0px;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 1;
}

.wish_close {
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
  padding: 0px;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 1;
}

.wish_close img {
  width: 25px;
  height: 25px;
  background-color: rgb(247 247 247);
  border-radius: 50%;
}

.wish_desc {
  font-family: "FuturaNormal";
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  padding: 0px 12px;
}

.wish_rate {
  font-family: "FuturaMedium";
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 12px;
  text-align: center;
}

.wish_rate b {
  font-family: "GFS Didot", serif;
  font-weight: 300;
}

.wish_btn button {
  font-family: "FuturaMedium";
  font-size: 16px;
  color: rgba(235, 60, 60, 1);
  letter-spacing: 0px;
  line-height: 12px;
  border: none;
  float: left;
  width: 100%;
  background-color: transparent;
  padding: 20px;
  border-top: 1px solid rgb(228 228 228);
  margin-top: 15px;
}

.wish_image {
  padding: 12px;
  cursor: pointer;
}

.wish_image img {
  width: 100%;
}

.video_shop {
  background-color: rgb(247 247 247);
  border: 1px solid rgba(240, 240, 240, 1);
  padding: 1.5rem;
  margin-bottom: 30px;
}

.video_shop li {
  list-style-type: none;
  float: left;
  width: 100%;
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 24px;
  margin-bottom: 20px;
}

.video_shop li:last-child {
  margin-bottom: 0px;
}

.video_shop li span:first-child {
  float: left;
  width: 40%;
  padding-right: 5%;
}

.video_shop li span:last-child {
  float: left;
  width: 60%;
}

.video_shop li a {
  color: rgba(10, 126, 209, 1);
}

.cart_container {
  padding: 40px 50px;
}

.cart_image img {
  width: 100%;
}

.cart_name {
  font-family: "FuturaMedium";
  font-size: 18px;
  color: rgb(0 0 0);
  line-height: 18px;
  margin-bottom: 18px;
}

.cart_weight {
  font-family: "FuturaNormal";
  font-size: 13px;
  color: rgba(76, 76, 76, 1);
  letter-spacing: 0px;
  line-height: 13px;
  margin-bottom: 18px;
}

.cart_quantity {
  font-family: "FuturaNormal";
  font-size: 16px;
  color: rgba(83, 83, 83, 1);
  line-height: 16px;
  margin-bottom: 18px;
}

.cart_quantity select {
  border: none;
  outline: none;
  background-color: transparent;
}

.cart_btn button {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  padding: 4px 10px;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgb(203 203 203);
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.cart_btn button:first-child {
  margin-right: 20px;
  background-color: rgb(255 255 255);
  color: rgb(0 0 0);
}

.cart_left {
  background-color: rgb(243 243 243);
  box-shadow: 0 1px 1px 1px rgb(0 0 0 / 16%);
  border-radius: 3px;
}

.cart_box {
  padding: 20px 15px;
  border-bottom: 1px dashed rgb(76, 76, 76);
}

.cart_box:last-child {
  border-bottom: none;
}

.cart_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  margin-bottom: 20px;
}

.cart_rate {
  font-family: "FuturaMedium";
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  line-height: 22px;
  text-align: right;
}

.cart_rate b {
  font-family: "GFS Didot", serif;
  font-weight: 300;
}

.check_head {
  font-family: "FuturaMedium";
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 10px;
}

.cart_coupon input {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  outline: none;
  border: 1px solid rgba(201, 201, 201, 1);
  border-radius: 4px;
  padding: 0px 25px;
  height: 45px;
  width: 75%;
}

.cart_coupon input::placeholder {
  color: rgba(142, 142, 142, 1);
}

.cart_coupon button {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);
  height: 45px;
  width: 21%;
  margin-left: 4%;
  font-family: "FuturaNormal";
  outline: none;
}

.cart_desc {
  padding: 20px 10px;
  margin-bottom: 25px;
  background-color: rgba(245, 245, 245, 1);
  box-shadow: 0 1px 1px 1px rgb(0 0 0 / 16%);
  border-radius: 3px;
}

.cart_desc li {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 15px;
  margin-bottom: 25px;
}

.cart_desc li:last-child {
  font-family: "FuturaMedium";
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 18px;
  border-top: 1px dashed rgb(203 203 203);
  padding-top: 25px;
  margin-bottom: 0px;
}

.cart_desc li b {
  font-family: "GFS Didot", serif;
  font-weight: 300;
}

.coupon_box {
  padding: 10px;
  margin-bottom: 25px;
  background-color: rgba(245, 245, 245, 1);
  box-shadow: 0 1px 1px 1px rgb(0 0 0 / 16%);
  border-radius: 3px;
}

.cart_coupon {
  display: flex;
  align-items: center;
}

.checkout_btn {
  font-family: "FuturaMedium";
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  line-height: 13px;
  background-color: rgb(0, 0, 0);
  border: none;
  height: 60px;
  border-radius: 4px;
}

.shipping_container {
  padding: 40px 100px 0px 100px;
}

.shipping_head {
  font-family: "GFS Didot", serif;
  font-size: 25px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 25px;
  margin-bottom: 32px;
}

.shipping_fields .form-group {
  margin-bottom: 38px;
}

.shipping_fields label {
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 12px;
  float: left;
  width: 100%;
}

.shipping_fields input,
.shipping_fields select {
  float: left;
  width: 100%;
  border: 1px solid rgba(118, 118, 118, 1);
  border-radius: 5px;
  height: 50px;
  outline: none;
  padding: 0px 15px;
  font-size: 14px;
}

.shipping_fields select {
  -webkit-appearance: menulist !important;
}

.shipping_note {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 83, 83, 1);
  line-height: 22px;
  text-transform: uppercase;
  background-color: rgba(255, 234, 234, 1);
  border: 1px solid rgba(245, 0, 0, 1);
  border-radius: 5px;
  padding: 15px 25px;
  display: flex;
  align-items: center;
}

.shipping_note i {
  color: rgba(245, 0, 0, 1);
  font-size: 22px;
  margin-right: 15px;
}

.shipping_list li {
  list-style-type: none;
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
}

.shipping_list li span {
  color: rgba(84, 84, 84, 1);
}

.shipping_list li b {
  font-family: "GFS Didot", serif;
  font-weight: 300;
}

.shipping_list li:last-child {
  font-size: 18px;
  font-family: "FuturaMedium";
  color: rgba(0, 0, 0, 1);
  border-top: 1px dashed rgb(217 216 216);
  padding-top: 20px;
  border-bottom: 1px solid rgb(217 216 216);
}

.shipping_list li:last-child span {
  color: rgba(0, 0, 0, 1);
}

.shipping_mode [type="radio"]:checked,
.shipping_mode [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.shipping_mode [type="radio"]:checked + label,
.shipping_mode [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 22px;
  display: inline-block;
  color: rgb(0, 0, 0);
  font-family: "FuturaNormal";
  font-size: 18px;
}

.shipping_mode [type="radio"]:checked + label:before,
.shipping_mode [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 100%;
  background: rgb(255, 255, 255);
}

.shipping_mode [type="radio"]:checked + label:after,
.shipping_mode [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: rgb(0, 0, 0);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.shipping_mode [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.shipping_mode [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.shipping_mode {
  margin: 25px 0px;
}

.shipping_mode_head {
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 18px;
}

.shipping_btn {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  border: none;
  text-align: center;
  color: rgb(255, 255, 255);
  height: 60px;
  outline: none;
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 500;
}

.est_date {
  margin-top: 15px;
}

.login_container {
  padding: 45px 50px;
  background-image: url("../images/backgroundimg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login_banner img {
  width: 100%;
}

.login_box {
  width: 582px;
  margin: 0% auto;
}

.login_cont {
  padding: 32px;
  background-color: rgba(244, 244, 244, 1);
}

.login_head {
  font-family: "FuturaMedium";
  font-size: 25px;
  font-weight: 400;
  color: rgba(36, 36, 36, 1);
  margin-bottom: 30px;
}

.login_head span {
  font-size: 18px;
  color: rgba(95, 95, 95, 1);
}

.login_cont .form-group {
  margin-bottom: 28px;
}

.login_cont input {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(118, 118, 118, 1);
  border-radius: 5px;
  float: left;
  width: 100%;
  padding: 18px 28px;
  outline: none;
  font-family: "FuturaNormal";
  font-size: 15px;
  color: rgba(0, 0, 0);
}

.login_cont input::placeholder {
  color: rgba(187, 187, 187, 1);
}

.login_ip {
  position: relative;
}

.login_ip input {
  padding-left: 84px;
}

.login_ip span {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  border-right: 1px solid rgba(118, 118, 118, 1);
  padding: 5px 20px;
  font-family: "FuturaNormal";
  color: rgba(187, 187, 187, 1);
}

.login_forgot {
  text-align: right;
  margin-top: 10px;
}

.login_forgot a {
  font-family: "FuturaNormal";
  font-size: 14px;
  font-weight: 400;
  color: rgba(27, 49, 255, 1);
  line-height: 17px;
  text-decoration: underline;
}

.login_btn {
  background-color: rgba(18, 53, 99, 1);
  border-radius: 5px;
  font-family: "FuturaBold";
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  height: 60px;
  border: none;
  text-align: center;
  outline: none;
  margin-bottom: 17px;
}

.google_login {
  background-color: rgba(63, 126, 232, 1);
  border-radius: 5px;
  font-family: "FuturaBold";
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  height: 60px;
  border: none;
  text-align: center;
  outline: none;
  margin-bottom: 23px;
}

.google_login img {
  margin-right: 20px;
}

.signup_btn {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(117, 117, 117, 1);
  line-height: 27px;
  text-align: center;
}

.signup_btn a {
  color: rgba(0, 0, 0, 1);
}

.contact_container {
  padding: 40px 50px;
}

.contact_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  margin-bottom: 30px;
}

.contact_left .form-group {
  margin-bottom: 28px;
}

.contact_left label {
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 23px;
  float: left;
  width: 100%;
}

.contact_left input {
  float: left;
  width: 100%;
  border: 1px solid rgba(118, 118, 118, 1);
  border-radius: 5px;
  height: 60px;
  padding: 0px 20px;
  outline: none;
}

.contact_btn {
  font-family: "FuturaMedium";
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 13px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 4px;
  height: 60px;
  border: none;
  margin-top: 20px;
}

.place_box {
  background-color: rgba(248, 249, 250, 1);
  border: 1px solid rgba(230, 232, 235, 1);
  border-radius: 5px;
  padding: 1.5rem;
  margin-top: 30px;
}

.place_box_head {
  color: rgb(27, 5, 5);
  font-size: 18px;
  letter-spacing: 1.5px;
  margin-bottom: 30px;
  font-family: "FuturaNormal";
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}

.place_box_head span {
  position: relative;
  padding-bottom: 15px;
}

.place_box_head span::before {
  background: rgb(27, 5, 5);
  height: 1px;
  width: 20px;
  position: absolute;
  bottom: 5px;
  content: "";
  left: 0px;
}

.place_box_cont ul li {
  list-style-type: none;
  margin-bottom: 25px;
  font-size: 15px;
  font-family: "FuturaNormal";
  letter-spacing: 0.5px;
  line-height: 24px;
  color: rgb(27, 5, 5);
  position: relative;
  padding-left: 35px;
}

.place_box_cont ul li:last-child {
  margin-bottom: 0px;
}

.place_box_cont ul li::before {
  content: "";
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 0px;
  top: 3px;
}

.place_box_cont ul li:nth-child(1)::before {
  background-image: url(../images/contact-address.png);
}

.place_box_cont ul li:nth-child(2)::before {
  background-image: url(../images/contac-phone.png);
}

.place_box_cont ul li:nth-child(3)::before {
  background-image: url(../images/contact-mail.png);
}

.place_box_cont ul li:nth-child(4)::before {
  background-image: url(../images/contact-time.png);
}

.about_cont img {
  float: left;
  padding-right: 25px;
}

.about_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
}

.about_tag {
  font-family: "FuturaNormal";
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
  margin: 30px 0px;
}

.about_cont {
  font-family: "FuturaNormal";
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  line-height: 28px;
  text-align: justify;
}

.about_container {
  padding: 3% 10% 0% 10%;
}

.faq_container {
  padding: 3% 10% 0% 10%;
}

.faq_box {
  background-color: rgba(251, 250, 248, 1);
  border: 1px solid rgba(240, 239, 237, 1);
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
  text-align: justify;
}

.faq_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  margin-bottom: 30px;
}

.faq_quest a {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1) !important;
  letter-spacing: 0.3px;
  line-height: 30px;
  text-decoration: none;
  font-family: "FuturaNormal";
  position: relative;
  padding-right: 30px;
  cursor: pointer;
  word-break: break-word;
}

.faq_quest a::before {
  position: absolute;
  content: "\f055";
  font-family: "Font Awesome 5 free";
  font-weight: 600;
  right: 0px;
  top: 0px;
  color: rgba(89, 89, 89, 1);
  font-size: 22px;
}

.faq_quest a.active::before {
  content: "\f056";
}

.faq_answer {
  font-size: 16px;
  color: rgba(89, 89, 89, 1);
  letter-spacing: 0px;
  line-height: 26px;
  font-family: "FuturaNormal";
  margin-top: 5px;
  text-align: justify;
  word-break: break-word;
}

.faq_answer p {
  margin: 0px;
}

.faq_answer ul {
  padding-left: 20px;
  margin-top: 5px;
}

.faq_answer ul li {
  font-size: 15px;
  margin-bottom: 10px;
}

.policy_box {
  margin-bottom: 30px;
}

.policy_head {
  font-family: "FuturaMedium";
  font-size: 20px;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
}

.policy_desc {
  font-family: "FuturaNormal";
  font-size: 18px;
  color: rgb(0, 0, 0);
  text-align: justify;
}

.policy_note {
  font-family: "FuturaNormal";
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-style: italic;
}

.policy_desc ul {
  padding: 15px 0px 15px 20px;
}

.policy_desc ul li {
  margin-bottom: 10px;
}

.gold_clr td {
  color: rgb(160, 121, 54);
}

#dailyRate .table {
  margin: 0px;
}

#dailyRate tr td {
  font-family: "FuturaNormal";
  border: none;
  text-transform: uppercase;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper button {
  width: 100%;
  height: 280px;
  background-color: rgba(248, 248, 248, 1);
  border: 2px dashed rgba(118, 118, 118, 1);
  border-radius: 5px;
}

.upload-btn-wrapper input[type="file"] {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.upload_file_box {
  padding: 20px 0px 10px 0px;
  border-bottom: 2px solid rgb(67 103 255);
}

.upload_file_name {
  font-family: "FuturaNormal";
  font-size: 18px;
  color: rgb(0, 0, 0);
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload_file_desc {
  font-family: "FuturaNormal";
  font-size: 15px;
  color: rgba(155, 155, 155, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploadfile_close {
  padding: 0px;
  margin: 0px;
  line-height: 0px;
  border: none;
  color: rgba(180, 174, 174, 1);
  background-color: transparent;
}

.emptycart_btn {
  text-align: center;
  margin-top: 60px;
}

.emptycart_btn button {
  font-family: "FuturaMedium";
  font-size: 18px;
  letter-spacing: 1px;
  color: rgb(255 255 255);
  background-color: rgb(0 0 0);
  border: 1px solid rgb(0, 0, 0);
  transition: 0.5s all ease;
  outline: none;
  padding: 1.4rem 4rem;
  text-decoration: none;
  border-radius: 4px;
}

#searchModal .modal-body {
  padding: 0px;
}

#searchModal .modal-body span {
  float: left;
  width: 100%;
  position: relative;
}

#searchModal .modal-body span::before {
  content: "";
  background-image: url(../images/header-icon6.svg);
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

#searchModal .modal-body input {
  border: none;
  border-radius: 4px;
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 17px;
  float: left;
  width: 100%;
  outline: none;
  padding: 20px 20px 20px 40px;
}

#searchModal .modal-body input::placeholder {
  color: rgba(165, 163, 163, 1);
}

#searchModal .modal-footer {
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.5rem;
  border-top: 1px solid rgb(235 235 235);
}

.search_btn {
  font-size: 13px;
  letter-spacing: 1px;
  color: rgb(255 255 255);
  background-color: rgb(0 0 0);
  border: 1px solid rgb(0, 0, 0);
  transition: 0.5s all ease;
  outline: none;
  padding: 10px;
  text-decoration: none;
  font-family: "FuturaMedium";
  width: 50%;
  text-transform: uppercase;
}

.close_btn {
  font-size: 13px;
  letter-spacing: 1px;
  color: rgb(0 0 0);
  background-color: rgb(255 255 255);
  border: 1px solid rgb(0, 0, 0);
  transition: 0.5s all ease;
  outline: none;
  padding: 10px;
  text-decoration: none;
  font-family: "FuturaMedium";
  width: 50%;
  text-transform: uppercase;
}

.promo_applied {
  font-family: "FuturaMedium";
  font-size: 16px;
  color: rgb(0, 0, 0);
  background-color: rgb(243 243 243);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.no_click {
  cursor: default !important;
}

.back_chain {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 14px;
  margin-top: 10px;
}

.back_chain a {
  color: rgba(0, 39, 241, 1) !important;
  text-decoration: underline !important;
  cursor: pointer;
}

#backChain .modal-body {
  padding: 1.5rem;
}

#backChain .close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.backchain_title {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 14px;
  text-align: center;
}

.backchain_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 20px;
}

.backchain_btn button {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  padding: 20px 5px;
  width: 45%;
  border: 1px solid rgba(187, 187, 187, 1);
  border-radius: 5px;
}

.backchain_tag {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: rgba(41, 10, 18, 1);
  margin-bottom: 10px;
}

.backchain_image img {
  width: 100%;
}

.backchain_btn button:first-child {
  color: rgba(255, 255, 255, 1);
  background-color: rgb(0, 0, 0);
}

.backchain_btn button:last-child {
  background-color: rgb(0, 0, 0);
  background-color: rgba(228, 228, 228, 1);
}

.backchain_click {
  text-align: center;
  margin-top: 25px;
}

.backchain_click button {
  font-family: "FuturaMedium";
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  padding: 20px 5px;
  width: 50%;
  border: none;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  background-color: rgb(0, 0, 0);
}

#shippingModal .faq_head {
  margin-bottom: 0px;
}

#certifiedModal .faq_head {
  margin-bottom: 0px;
}

.enquiry_head {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 22px;
  text-align: center;
}

.enquiry_cont .form-group {
  margin-bottom: 20px;
}

.enquiry_cont .form-group input {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 23px;
  border: 1px solid rgba(118, 118, 118, 1);
  border-radius: 5px;
  outline: none;
  float: left;
  width: 100%;
  padding: 16px 20px;
}

.enquiry_cont .form-group input::placeholder {
  color: rgba(118, 118, 118, 1);
}

.mode_tag {
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 400;
  color: rgb(71, 71, 71);
  margin-bottom: 15px;
}

.mode_inp li {
  list-style-type: none;
  display: inline-block;
  margin-right: 30px;
}

.mode_inp li:last-child {
  margin-right: 0px;
}

.mode_inp li label {
  margin-bottom: 0px;
}

.mode_inp [type="radio"]:checked,
.mode_inp [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.mode_inp [type="radio"]:checked + label,
.mode_inp [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  line-height: 22px;
  display: inline-block;
  color: rgb(0, 0, 0);
  font-family: "FuturaNormal";
  font-size: 15px;
}

.mode_inp [type="radio"]:checked + label:before,
.mode_inp [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 100%;
  background: rgb(255, 255, 255);
}

.mode_inp [type="radio"]:checked + label:after,
.mode_inp [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: rgb(0, 0, 0);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.mode_inp [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mode_inp [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.mode_check {
  margin-top: 30px;
}

.mode_check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.mode_check label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 15px;
  font-family: "FuturaNormal";
  display: flex;
  align-items: flex-start;
}

.mode_check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
}

.mode_check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid rgba(0, 0, 0, 1);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.mode_btn {
  text-align: center;
  margin-top: 35px;
}

.mode_btn button {
  font-family: "FuturaNormal";
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
  line-height: 13px;
  background-color: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  padding: 18px 70px;
}

#enquiryModal .modal-body {
  padding: 1.5rem;
}

#enquiryModal .modal-body .close {
  position: absolute;
  right: 5px;
  top: 5px;
  opacity: 1;
}

.thankyou_container {
  padding: 0px 20px;
}

.thankyou_image {
  text-align: center;
}

.thankyou_desk {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 400;
  color: rgba(71, 71, 71, 1);
  text-align: center;
  letter-spacing: 0px;
  line-height: 40px;
}

.contact_right iframe {
  width: 100%;
  height: 524px;
  pointer-events: auto !important;
}

.heart_colour {
  color: red;
}

.out_stock.flt {
  color: red;
  padding-top: 10px;
}

.display1 {
  opacity: 0.5;
  pointer-events: none;
}
.detail_pin_note {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
  margin-top: 10px;
}

.modal-content {
  -webkit-animation: SHOW-BOX 0.8s ease;
  -moz-animation: SHOW-BOX 0.8s ease;
  -o-animation: SHOW-BOX 0.8s ease;
  animation: SHOW-BOX 0.8s ease;
}

@-webkit-keyframes SHOW-BOX {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-moz-keyframes SHOW-BOX {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-o-keyframes SHOW-BOX {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes SHOW-BOX {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.cart_txt {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.cartpg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
}

.cartpg img {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 60px);
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.8, 0.8);
  }

  100% {
    transform: scale(1, 1);
  }
}

.opac {
  opacity: 0.7;
}

.decide_box {
  text-align: center;
}

#decideModal .modal-body {
  padding: 30px;
}

#decideModal .close img {
  width: 30px;
  height: 30px;
}

#decideModal .close {
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
}

.decide_image {
  margin-bottom: 30px;
}

.decide_txt {
  font-size: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  font-family: "FuturaNormal";
}

#decideModal .modal-body {
  padding: 30px;
}

#dailyRate .modal-body {
  position: relative;
  background-image: url("../images/daily-rate-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px 12px !important;
  border-radius: 0px 0px 6px 6px;
}

#dailyRate .modal-header {
  border: none !important;
}

#dailyRate thead th {
  border: none;
  border-bottom: 1px solid rgb(215 215 215);
  padding: 10px 0px;
}

#dailyRate thead th:nth-child(2) {
  text-align: right;
}

#dailyRate tbody tr td {
  padding: 8px 0px;
}

.dailyrate_1st td {
  padding-top: 16px !important;
}

#dailyRate tbody tr td:nth-child(2) {
  text-align: right;
}

#dailyRate .modal-title {
  font-family: "FuturaMedium";
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;
}

#dailyRate .modal-title img {
  margin-right: 10px;
}

#dailyRate thead tr th {
  font-family: "FuturaMedium";
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
}

#dailyRate .modal-content {
  border-radius: 6px;
}

#decideModal .close {
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
}

.decide_image {
  margin-bottom: 30px;
}

.decide_txt {
  font-size: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  font-family: "FuturaNormal";
}

#dailyRate .modal-body {
  position: relative;
  background-image: url("../images/daily-rate-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px 12px !important;
  border-radius: 0px 0px 6px 6px;
}

#dailyRate .modal-header {
  border: none !important;
}

#dailyRate thead th {
  border: none;
  border-bottom: 1px solid rgb(215 215 215);
  padding: 10px 0px;
}

#dailyRate thead th:nth-child(2) {
  text-align: right;
}

#dailyRate tbody tr td {
  padding: 8px 0px;
}

.dailyrate_1st td {
  padding-top: 16px !important;
}

#dailyRate tbody tr td:nth-child(2) {
  text-align: right;
}

#dailyRate .modal-title {
  font-family: "FuturaMedium";
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;
}

#dailyRate .modal-title img {
  margin-right: 10px;
}

#dailyRate thead tr th {
  font-family: "FuturaMedium";
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
}

#dailyRate .modal-content {
  border-radius: 6px;
}

#rcc-decline-button,
#rcc-confirm-button {
  font-size: 13px;
}

.netweight_info {
  position: relative;
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
}

.netweight {
  position: absolute;
  right: 0px;
  top: 25px;
  background-color: rgb(243 243 243);
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgb(119 119 119 / 60%);
}

.netweight_info span {
  margin-left: 10px;
  cursor: pointer;
}

.netweight_info span:hover .netweight {
  display: block !important;
}

.rs_font {
  font-family: "GFS Didot", serif !important;
}

.feedback_main {
  padding: 2% 28% 0% 28%;
}

.feedback_box {
  box-shadow: 0px 4px 8px rgb(195 195 195 / 70%);
  padding: 30px;
  border-radius: 10px;
}

.feedback_box .form-group {
  margin-bottom: 40px;
}

.feedback_box label {
  float: left;
  width: 100%;
  font-family: "FuturaNormal";
  font-size: 18px;
  color: rgb(0 0 0);
  margin-bottom: 15px;
}

.star_ans i {
  display: inline-block;
  color: rgba(191, 191, 191, 1);
  font-size: 18px;
  margin-right: 20px;
  cursor: pointer;
}

.star_ans i:last-child {
  margin-right: 0px;
}

.star_ans i.yellow_star {
  color: rgb(246 183 5);
}

.feedback_box textarea {
  float: left;
  width: 100%;
  outline: none;
  border: 1px solid rgba(191, 191, 191, 1);
  border-radius: 6px;
  font-family: "FuturaNormal";
  font-size: 16px;
  color: rgb(0 0 0);
  padding: 10px;
}

.feedback_box button {
  font-family: "FuturaNormal";
  font-size: 16px;
  background-color: rgb(0, 0, 0);
  outline: none;
  float: left;
  width: 100%;
  border: none;
  color: rgb(255, 255, 255);
  padding: 18px 5px;
  border-radius: 4px;
}

#searchModal .modal-dialog,
#dailyRate .modal-dialog {
  margin-top: 10%;
}
.policy_desc p .row {
  margin: 0px !important;
}

/* Media Query */
@media (min-width: 320px) and (max-width: 479px) {
  .other_option li a {
    font-size: 10px;
  }
  #searchModal .modal-dialog,
  #dailyRate .modal-dialog {
    margin-top: 45%;
  }

  .copyright_left ul li {
    padding: 0px 10px;
  }

  .product_right {
    margin-top: 25px;
  }

  .detail_rate {
    line-height: 28px;
  }

  .detail_rate span {
    font-size: 28px;
    margin-right: 0px;
    float: left;
    width: 100%;
  }

  .detail_pin_box {
    padding: 15px;
  }

  .detail_pin_cont {
    display: block;
  }

  .detail_pin_cont .pincode_left {
    width: 100%;
    margin-bottom: 10px;
  }

  .detail_pin_cont button {
    width: 100%;
    margin-left: 0px;
  }

  .specification_desc .col-sm-6:last-child {
    margin-top: 45px;
  }

  .related_cont .col-sm-3 {
    display: inline-block;
    max-width: 75%;
    padding: 0px 15px;
  }

  .profile_left {
    padding-right: 0%;
    margin-bottom: 40px;
  }

  .order_main .nav li a {
    font-size: 18px;
  }

  .wish_box {
    margin-bottom: 30px;
  }

  .whish_main .col-sm-3:last-child .wish_box {
    margin-bottom: 0px;
  }

  .cart_rate {
    text-align: left;
    margin-bottom: 25px;
  }

  .shipping_note {
    display: block;
    padding: 10px;
    font-size: 15px;
    line-height: 24px;
  }

  .login_box {
    width: 100%;
  }

  .login_cont {
    padding: 15px;
  }

  .back_chain {
    line-height: 24px;
  }

  .backchain_title {
    line-height: 30px;
  }

  .thankyou_image img {
    width: 100%;
  }

  .emptycart_btn button {
    padding: 1.4rem 3rem;
  }

  .thankyou_container {
    padding-top: 70px;
  }

  .award_right {
    font-size: 23px;
    line-height: 46px;
  }

  .customer_left span {
    font-size: 23px;
  }

  .mode_inp li {
    margin-bottom: 15px;
  }

  .mode_inp li:last-child {
    margin-bottom: 0px;
  }

  .detail_identity {
    display: block;
  }

  .detail_identity li {
    float: left;
    width: 50%;
    min-height: 60px;
    margin: 10px 0px;
  }

  .shipping_note {
    margin-bottom: 30px;
  }

  .shipping_right {
    padding: 0px 30px !important;
  }

  .mode_tag {
    font-size: 18px;
  }

  .video_shop li span:first-child {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .video_shop li span:last-child {
    width: 100% !important;
  }

  .feedback_box {
    padding: 15px;
  }

  .copyright_left {
    text-align: center;
  }

  .feature_container .col-sm-3 {
    max-width: 50%;
    padding: 0px 10px;
  }

  .feature_box {
    min-height: 65px;
    padding: 0px 5px;
    margin-bottom: 20px !important;
  }

  .feature_icon img {
    width: 30px;
  }

  .feature_txt {
    font-size: 8px;
    line-height: 14px;
  }

  .buybox_txt {
    font-size: 8px;
    line-height: 14px;
  }

  .feature_txt span {
    font-size: 10px;
  }
  .cart_image {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .category_box {
    margin: 10px;
  }

  .product_right {
    margin-top: 25px;
  }

  .specification_desc .col-sm-6:last-child {
    margin-top: 45px;
  }

  .related_cont .col-sm-3 {
    display: inline-block;
    max-width: 75%;
    padding: 0px 15px;
  }

  .profile_left {
    padding-right: 0%;
    margin-bottom: 40px;
  }

  .wish_box {
    margin-bottom: 30px;
  }

  .whish_main .col-sm-3:last-child .wish_box {
    margin-bottom: 0px;
  }

  .shipping_note {
    line-height: 24px;
  }

  .login_box {
    width: 100%;
  }

  .thankyou_container {
    padding-top: 100px;
  }

  .award_right {
    font-size: 30px;
    line-height: 54px;
  }

  .customer_left span {
    font-size: 30px;
  }

  .detail_identity {
    display: block;
  }

  .detail_identity li {
    float: left;
    width: 50%;
    min-height: 60px;
    margin: 10px 0px;
  }

  .detail_rate {
    line-height: 28px;
  }

  .detail_rate span {
    font-size: 28px;
    margin-right: 0px;
    float: left;
    width: 100%;
  }

  .shipping_note {
    margin-bottom: 30px;
  }

  .shipping_right {
    padding: 0px 30px !important;
  }

  .mode_tag {
    font-size: 18px;
  }

  .copyright_left {
    text-align: center;
  }

  .feature_container .col-sm-3 {
    max-width: 50%;
    padding: 0px 10px;
  }

  .feature_box {
    min-height: 65px;
    padding: 0px 10px;
    margin-bottom: 20px !important;
  }

  .feature_icon img {
    width: 30px;
  }

  .feature_txt {
    font-size: 10px;
    line-height: 16px;
  }

  .feature_txt span {
    font-size: 12px;
  }
  .cart_image {
    margin-bottom: 20px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .catregory_cont .col-sm-3 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .feature_container .col-sm-3 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .what_cont .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .product_list .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .product_container .col-sm-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .product_container .col-sm-9 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .product_name {
    font-size: 10px;
  }

  .product_rate {
    font-size: 12px;
  }

  .product_weight {
    font-size: 10px;
    margin-top: 2px;
  }

  .product_box {
    padding: 10px;
    margin-top: 30px;
  }

  .product_image {
    margin-bottom: 10px;
  }

  .filter_head a {
    font-size: 14px;
  }

  .filter_input {
    display: block;
  }

  .filter_input span {
    width: 100%;
    margin-right: 0px;
    position: relative;
    float: left;
    margin-bottom: 15px;
  }

  .filter_input button {
    width: 100%;
    padding: 15px;
  }

  .filter_head {
    padding: 20px 0px;
  }

  .related_cont .col-sm-3 {
    display: inline-block;
    max-width: 40%;
    padding: 0px 15px;
  }

  .detail_pin_cont {
    display: block;
  }

  .pincode_left {
    width: 100%;
    margin: 0px 0px 10px 0px;
  }

  .detail_pin_cont button {
    width: 100%;
    margin: 0px;
  }

  .detail_pin_box {
    padding: 15px;
  }

  .profile_container .col-sm-3 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .profile_container .col-sm-9 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .profile_right_cont .col-sm-6 {
    flex: 100%;
    max-width: 100%;
  }

  .profile_left {
    padding-right: 0%;
  }

  .order_cont .col-sm-8,
  .order_cont .col-sm-4 {
    flex: 100%;
    max-width: 100%;
  }

  .whish_main .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 10px;
    margin-bottom: 10px;
  }

  .cart_col_left {
    flex: 100%;
    max-width: 100%;
  }

  .cart_col_right {
    flex: 100%;
    max-width: 100%;
    margin-top: 20px;
  }

  .shipping_fields .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .shipping_note {
    line-height: 24px;
  }

  .contact_cont .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .place_box {
    min-height: 305px;
  }

  .thankyou_container {
    padding-top: 140px;
  }

  .vdo_shopp .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .award_container .col-sm-5,
  .award_container .col-sm-7,
  .customer_container .col-sm-5,
  .customer_container .col-sm-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .shipping_left,
  .shipping_right {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .row.shipp_box {
    margin: 40px 0px 0px 0px;
  }

  .specification_box:last-child {
    margin-bottom: 0px;
  }

  .detail_identity {
    display: block;
  }

  .detail_identity li {
    float: left;
    width: 50%;
    min-height: 60px;
    margin: 10px 0px;
  }

  .detail_rate {
    line-height: 28px;
  }

  .detail_rate span {
    font-size: 28px;
    margin-right: 0px;
    float: left;
    width: 100%;
  }

  .contact_cont .col-sm-5,
  .contact_cont .col-sm-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact_location .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .product_container .col-sm-2,
  .product_container .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header_container {
    padding: 30px 25px;
  }

  .header_logo {
    text-align: center;
    /* width: 24%; */
  }

  .header_logo img {
    width: 100%;
  }

  .header_links {
    width: 38%;
  }

  .header_links ul li {
    padding: 0px 10px;
  }

  .header_links ul li a {
    font-size: 9px;
  }

  .header_links button {
    font-size: 9px;
    padding: 5px;
    margin-left: 8px;
    margin-right: 5px;
  }

  .award_left::before {
    display: none;
  }

  .customer_right::before {
    display: none;
  }

  .catregory_cont .col-sm-3 {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 20px;
  }

  .what_cont .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .feature_container .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }

  .copyright_right img {
    width: 100%;
  }

  .award_right {
    font-size: 28px;
    line-height: 50px;
    padding-left: 5%;
  }

  .customer_left {
    text-align: right;
    padding-right: 5%;
  }

  .customer_left span {
    font-size: 28px;
    line-height: 50px;
  }

  .filter_input {
    display: block;
  }

  .filter_input span {
    width: 100%;
    margin-right: 0px;
    position: relative;
    float: left;
    margin-bottom: 15px;
  }

  .filter_input button {
    width: 100%;
    padding: 15px;
  }

  .product_list .col-sm-4 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .product_name {
    font-size: 10px;
  }

  .product_rate {
    font-size: 12px;
  }

  .product_weight {
    font-size: 10px;
    margin-top: 2px;
  }

  .detail_btn button {
    width: 50%;
  }

  .detail_note li {
    width: 100%;
  }

  .detail_pin_box {
    padding: 10px;
  }

  .pincode_left {
    width: 65%;
  }

  .detail_pin_cont button {
    width: 31%;
  }

  .detail_pin_label {
    line-height: 24px;
    margin-bottom: 15px;
  }

  .profile_container {
    padding: 50px 30px;
  }

  .profile_container .col-sm-3 {
    flex: 0 0 35%;
    max-width: 35%;
  }

  .profile_container .col-sm-9 {
    flex: 0 0 65%;
    max-width: 65%;
  }

  .profile_left {
    padding-right: 0%;
  }

  .order_cont .col-sm-8,
  .order_cont .col-sm-4 {
    flex: 100%;
    max-width: 100%;
  }

  .order_desc {
    padding: 5%;
  }

  .order_btn {
    padding: 5%;
  }

  .order_btn button {
    width: 100%;
  }

  .order_box_head {
    padding: 5px 10px;
    display: block;
  }

  .order_box_head li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px;
  }

  .total_pop {
    left: auto;
    right: 0px;
  }

  .whish_main .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 10px;
    margin-bottom: 10px;
  }

  .cart_col_left,
  .cart_col_right {
    flex: 100%;
    max-width: 100%;
  }

  .card_right {
    margin-top: 25px;
  }

  .shipping_container {
    padding: 40px 40px 0px 40px;
  }

  .contact_main {
    padding: 0% !important;
  }

  .contact_cont .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .place_box {
    min-height: 280px;
  }

  .about_container {
    padding: 4% 5% 0% 5%;
  }

  .faq_container {
    padding: 40px 50px 0px 50px;
  }

  .upload-btn-wrapper button {
    height: 200px;
  }

  .video_shop li span b {
    float: right;
    padding-left: 2%;
  }

  .vdo_shopp .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .video_shop {
    margin-bottom: 30px;
  }

  .shipping_left,
  .shipping_right {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .row.shipp_box {
    margin: 40px 0px 0px 0px;
  }

  .detail_rate {
    line-height: 30px;
  }

  .detail_rate span {
    float: left;
    width: 100%;
  }

  .detail_identity {
    display: block;
  }

  .detail_identity li {
    float: left;
    width: 50%;
    min-height: 60px;
    margin: 10px 0px;
  }

  .contact_location .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact_location_ip .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .footer_cont ul li a {
    font-size: 12px;
  }

  .product_container .col-sm-2,
  .product_container .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .detail_enq {
    font-size: 13px;
    text-align: justify;
  }

  .feedback_main {
    padding: 2% 10% 0% 10%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .what_button {
    text-align: center;
    margin-top: 20px;
  }

  .what_space {
    margin-bottom: 24px;
  }

  .award_right {
    font-size: 32px;
    line-height: 60px;
  }

  .customer_left span {
    font-size: 32px;
    line-height: 60px;
  }

  .feature_container .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }

  .copyright_right img {
    width: 100%;
  }

  .header_logo img {
    width: 100%;
  }

  .header_links ul li {
    padding: 0px 5px;
  }

  .header_links button {
    padding: 5px;
    margin-left: 10px;
  }

  .header_links ul li a {
    font-size: 10px;
  }

  .product_list .col-sm-4 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .detail_note li {
    width: 100%;
  }

  .profile_container {
    padding: 50px 40px;
  }

  .profile_left {
    padding-right: 0%;
  }

  .profile_left li a {
    font-size: 18px;
  }

  .order_cont .col-sm-8,
  .order_cont .col-sm-4 {
    flex: 100%;
    max-width: 100%;
  }

  .order_desc {
    padding: 5%;
  }

  .order_btn {
    padding: 5%;
  }

  .order_btn button {
    width: 100%;
  }

  .order_box_head {
    padding: 5px 10px;
    display: block;
  }

  .order_box_head li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px;
  }

  .total_pop {
    left: auto;
    right: 0px;
  }

  .whish_main .col-sm-3 {
    padding: 0px 5px;
  }

  .cart_col_left,
  .cart_col_right {
    flex: 100%;
    max-width: 100%;
  }

  .card_right {
    margin-top: 25px;
  }

  .contact_main {
    padding: 0% !important;
  }

  .place_box {
    min-height: 400px !important;
  }

  .about_container {
    padding: 4% 5% 0% 5%;
  }

  .faq_container {
    padding: 40px 50px 0px 50px;
  }

  .video_shop li span b {
    float: right;
  }

  .vdo_shopp .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .video_shop {
    margin-bottom: 30px;
  }

  .shipping_container {
    padding: 40px 40px 0px 40px;
  }

  .shipping_left,
  .shipping_right {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .row.shipp_box {
    margin: 40px 0px 0px 0px;
  }

  .contact_location .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact_location_ip .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .product_container .col-sm-2 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .product_container .col-sm-10 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .feedback_main {
    padding: 2% 10% 0% 10%;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .header_links ul li {
    padding: 0px 5px;
  }

  .profile_container {
    padding: 50px;
  }

  .profile_left {
    padding-right: 8%;
  }

  .contact_main {
    padding: 0% 5% !important;
  }

  .place_box {
    min-height: 280px;
  }

  .detail_pin_box {
    width: 80% !important;
  }

  .shipping_container {
    padding: 40px 40px 0px 40px;
  }

  .feedback_main {
    padding: 2% 20% 0% 20%;
  }
}

@media (max-width: 767px) {
  .mobile_hide {
    display: none;
  }

  .buyback_box {
    margin-bottom: 20px;
  }
  .header_container {
    padding: 5px 20px 5px 20px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
    border-bottom: 1px solid rgb(196 196 196);
    justify-content: center;
    width: 100%;
  }

  .header_logo {
    z-index: 9;
    position: relative;
  }

  .header_logo img {
    width: 100%;
    height: auto;
  }

  .copyright_right {
    margin-top: 15px;
  }

  .copyright_right img {
    width: 100%;
  }

  .footer_container {
    display: block;
  }

  .footer_box {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(231, 231, 231, 1);
  }

  .footer_box:last-child {
    margin-bottom: 0px;
    border-bottom: none;
  }

  .footer_head {
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer_head i {
    transform: rotate(0deg);
    transition: 0.5s all ease-in-out;
    font-size: 14px;
  }

  .footer_head i.chevron-rotate {
    transform: rotate(-180deg);
    transition: 0.5s all ease-in-out;
  }

  .award_left::before {
    display: none;
  }

  .customer_right::before {
    display: none;
  }

  .category_container {
    padding: 40px 15px 0px 15px;
  }

  .catregory_cont .col-sm-3:last-child .category_box {
    margin-bottom: 0px;
  }

  .what_container {
    padding: 40px 20px;
  }

  .what_box {
    margin-bottom: 25px;
  }

  .what_cont .col-sm-4:last-child .what_box:last-child {
    margin-bottom: 0px !important;
  }

  .award_container {
    padding: 40px 20px 50px 20px;
  }

  .award_right {
    padding-left: 0%;
    margin-top: 20px;
  }

  .customer_container {
    padding: 40px 20px 50px 20px;
  }

  .customer_left {
    padding-right: 0%;
    margin-bottom: 30px;
    margin-bottom: 30px;
  }

  .feature_container {
    padding: 60px 20px 0px 20px;
  }

  .feature_box {
    margin-bottom: 30px;
  }

  .feature_container .col-sm-3:last-child .feature_box {
    margin-bottom: 0px;
  }

  .footer_container {
    padding: 50px 20px;
  }

  .copyright_container {
    padding: 13px 20px;
  }

  .magnifier {
    left: 50% !important;
    transform: translateX(-50%);
  }

  .detail_left {
    margin-bottom: 30px;
  }

  .detail_btn button {
    font-size: 16px;
    padding: 10px;
    width: 50%;
  }

  .detail_note li {
    width: 100%;
    min-height: auto;
    margin-bottom: 25px;
  }

  .detail_note li:last-child {
    margin-bottom: 0px;
  }

  .detail_pin_label {
    line-height: 25px;
    margin-bottom: 15px;
  }

  .empty_order_image img {
    width: 100%;
  }

  .order_box_head {
    display: block;
    padding: 6px 10px;
  }

  .order_box_head li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .total_pop {
    width: 290px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .order_desc {
    padding: 5%;
  }

  .order_btn {
    padding: 5%;
  }

  .order_btn button {
    width: 100%;
  }

  .divider_main {
    padding: 50px 20px 0px 20px;
  }

  .arrow_head span {
    font-size: 18px;
  }

  .arrow_head span::before {
    height: 1px;
  }

  .card_right {
    margin-top: 30px;
  }

  .detail_enq {
    margin-top: 20px;
  }

  .about_container {
    padding: 40px 20px 0px 20px;
  }

  .faq_container {
    padding: 40px 20px 0px 20px;
  }

  .contact_container {
    padding: 40px 20px 40px 20px;
  }

  .product_container {
    padding: 40px 20px;
  }

  .detail_container {
    padding: 40px 20px;
  }

  .cart_container {
    padding: 40px 20px;
  }

  .shipping_container {
    padding: 40px 20px;
  }

  .profile_container {
    padding: 40px 20px;
  }

  .login_container {
    padding: 40px 20px;
  }

  .video_shop li span b {
    padding-left: 2%;
  }

  .video_shop {
    margin-bottom: 30px;
  }

  .catregory_cont .slick-slide > div {
    padding: 0 5px;
  }

  .category_box {
    padding: 4px;
    margin: 10px 0px;
  }

  .category_name {
    font-size: 15px;
    line-height: 18px;
    padding: 8px 0px 4px 0px;
  }

  .contact_btn {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .order_desc .order_name {
    font-size: 20px;
  }

  .order_desc .order_code {
    font-size: 14px;
  }

  .order_desc .order_rate {
    font-size: 14px;
  }

  .order_desc li {
    line-height: 22px;
    margin-bottom: 10px;
  }

  .order_descss .order_name {
    font-size: 20px;
  }

  .order_descss .order_code {
    font-size: 14px;
  }

  .order_descss .order_rate {
    font-size: 14px;
  }

  .order_descss li {
    line-height: 22px;
    margin-bottom: 10px;
  }

  .feedback_main {
    padding: 8% 4% 0% 4%;
  }
}

@media (min-width: 768px) {
  .hide_desktop {
    display: none;
  }

  .video_pop .modal-dialog {
    max-width: 765px;
  }

  .specification_desc .col-sm-6:last-child {
    padding-left: 10%;
  }

  .specification_desc .col-sm-6:first-child {
    padding-right: 10%;
  }

  .detail_container .breadcrumbs {
    margin-bottom: 35px;
  }

  .order_cont .row {
    align-items: center;
  }

  .cart_main {
    padding: 0% 5%;
  }

  .contact_main {
    padding: 0% 10%;
  }

  .place_box {
    min-height: 376px;
  }

  .specification_desc .col-sm-4:nth-child(1) {
    padding-right: 4%;
  }

  .specification_desc .col-sm-4:nth-child(2) {
    padding: 0% 2%;
  }

  .specification_desc .col-sm-4:nth-child(3) {
    padding-left: 4%;
  }

  .product_expl .col-sm-6:nth-child(2) {
    padding-left: 2%;
  }

  .product_expl .col-sm-6:nth-child(1) {
    padding-right: 2%;
  }

  .specification_box:last-child {
    margin-bottom: 0px;
  }

  #decideModal .modal-dialog-centered {
    max-width: 650px;
    margin: 0% auto;
  }

  .footer_cont ul li:last-child {
    margin-bottom: 0px;
  }

  .footer_head i {
    display: none;
  }
}

@media (min-width: 1200px) {
  .video_shop li {
    display: flex;
    align-items: flex-end;
  }

  .video_shop li span b {
    float: right;
  }

  .detail_pin_box {
    width: 65%;
  }
}
